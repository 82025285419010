<template>
  <div class="app">
    <toubu></toubu>
    <div style="clear: both;" class="">
      <BDhear :num="2"></BDhear>
    </div>
    <div class="app-xt">
      <div class="c-div">
        <div class="conter">
          <div class="heard">
            <img class="imgs1" src="./../../assets/xinde/zuo.jpg" alt="" />
            <div>准入信息</div>
            <img class="imgs2" src="./../../assets/xinde/you.jpg" alt="" />
          </div>
          <div class="list">
            <div class="left">
              <p>平台编码：</p>
              <p>{{ info.platformcode }}</p>
            </div>
            <div class="right">
              <p>交易平台：</p>
              <p>南阳市公共资源交易平台</p>
            </div>
          </div>
          <div class="list">
            <div class="left">
              <p>企业名称：</p>
              <p>{{ info.biddername }}</p>
            </div>
            <div class="right">
              <p>社会统一信用代码：</p>
              <p>{{ info.biddercode }}</p>
            </div>
          </div>

          <div class="list">
            <div class="left">
              <p>联系人：</p>
              <p>{{ info.jbrname }}</p>
            </div>
            <div class="right">
              <p>联系人手机号：</p>
              <p>{{ info.jbrphone }}</p>
            </div>
          </div>
          <div class="list">
            <div class="tishi">
              <span class="aa">提示：</span>
              <span class="bb"
                >若长时间授信未通过，您可以咨询授信机构。咨询电话:400-811-5272</span
              >
            </div>
          </div>
          <div class="heard">
            <img class="imgs1" src="./../../assets/xinde/zuo.jpg" alt="" />
            <div>信用评估</div>
            <img class="imgs2" src="./../../assets/xinde/you.jpg" alt="" />
          </div>
          <div style="" class="list1">
            <div class="bottsssom">
              <!-- {{ qiye.credittype==0 ? '等待评价' : qiye.credittype==1 ? '评价通过' : "评价拒绝" }}: -->
              <p>{{ qiye.orgincredit }}</p>
            </div>
          </div>
          <div class="heard">
            <img class="imgs1" src="./../../assets/xinde/zuo.jpg" alt="" />
            <div>申请记录</div>
            <img class="imgs2" src="./../../assets/xinde/you.jpg" alt="" />
          </div>
          <div style="margin-top: 20px;" class="list1">
            <div class="bottom">
              <p>单位名称</p>
              <p>{{ info.biddername }}</p>
            </div>
            <div class="bottom">
              <p>担保机构</p>
              <p>{{ info.guaranteeCompanyName }}</p>
            </div>
            <div class="bottom">
              <p>申请日期</p>
              <p>{{ info.timestamp }}</p>
            </div>
            <!-- <div class="bottom">
              <p>申请状态</p>
              <p>{{ info.state==0?'审核中':info.state==1?'审核通过':'' }}</p>
            </div> -->
          </div>
          <div class="anniu">
            <div class="btn" @click="next()">下一步</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="publicDialog"
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <!-- 信用评估分析=>0.等待评价，1.评价通过,2.评价拒绝3.等待人工审核 -->

      <span v-if="qiye.credittype == 2">审核拒绝无法申请</span>
      <span v-else-if="qiye.credittype == 3"> 系统拒绝等待人工审核。</span>
      <span v-else-if="qiye.credittype == 1"> 审核通过。</span>
      <span v-else> {{ qiye.orgincredit }}</span>
      <span slot="footer" class="dialog-footer footer">
        <div class="foossster">
          <el-button @click="xiayibu">确 定</el-button>
        </div>
      </span>
    </el-dialog>
    <dinad class="qingchu"></dinad>
  </div>
</template>

<script>
import dinad from '../../components/dinad.vue'
import toubu from '../../components/toubu.vue'
import $ from 'jquery'
// import jquery from 'jquery'
import { orgcredit, applyprogress } from '@/api/user'
import axios from 'axios'
import BDhear from '../../components/BDhear.vue'
// import "./js/modernizr.js";
// import "./js/main"
export default {
  components: {
    dinad,
    BDhear,
    toubu
  },
  data() {
    return {
      info: {},
      dialogVisible: false,
      qiye: { orgincredit: '' }
    }
  },
  created() {
    this.applyno = sessionStorage.getItem('applyno')
    // console.log(this.applyno, 1231313);
    this.bbv()
    this.nextx()
  },

  methods: {
    xiayibu() {
      if (this.qiye.orgincredit == 1) {
        this.$router.push('/xieyi')
      } else {
        this.dialogVisible = false
      }
    },
    async bbv() {
      var that = this
      applyprogress(this.applyno).then((res) => {
        // console.log("查询详情--准入信息", res.data.data.bahan);
        that.info = res.data.data.bahan
        // if (res.data.code == 1) {
        // }
      })
      // $.support.cors = true;
      // $.ajax({
      //   url:
      //     `https://www.hnzyxsbh.com/tripartite/mobile/agreement/getapplyinfo?applyno=` +
      //     this.applyno,
      //   data: "",
      //   crossDomain: true,
      //   contentType: "text/plain",
      //   type: "POST",
      //   dataType: "jsonp",
      // }).done(function(data) {
      //   console.log("/tripartite/mobile/agreement/getapplyinfo", data.data);
      //   that.info = data.data;
      //   that.nextx()
      // });
    },
    nextx() {
      orgcredit({ applyno: this.applyno }).then((res) => {
        console.log('查询企业授信', res)
        if (res.data.code == 200) {
          this.qiye = res.data.data
          if (
            this.qiye.orgincredit == '正在对企业进行信用评估分析，请稍后。。。'
          ) {
            this.qiye.orgincredit =
              '正在对企业进行信用评估分析，请稍后自行刷新页面'
          }
          if (res.data.data.credittype == 1) {
            // this.$router.push('/xieyi')
          } else {
            this.dialogVisible = true
          }
        }
      })
    },
    next() {
      orgcredit({ applyno: this.applyno }).then((res) => {
        // console.log("查询企业授信", res);
        if (res.data.code == 200) {
          // this.qiye = res.data.data;
          // res.data.data.credittype=1 //王书豪测试
          if (res.data.data.credittype == 1) {
            this.$router.push('/xieyi')
          } else {
            this.dialogVisible = true
          }
        }
      })
    }
  }
}
</script>

<style scoped>
html {
  width: 100vw;
  width: 100%;
  background: #eeee !important;
}

.app {
  height: 100%;
}
.foossster {
  text-align: center !important;
}
.app-xt {
  width: 100%;
  /* height: 70%; */
  background-color: #fff;
}

.c-div {
  width: 70%;
  height: 80%;
  margin: 1vh auto;
  background-color: #f5f6f8;
  padding: 10px 0 10px;
}

.conter {
  width: 80%;
  margin: 0 auto;
}

.heard {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
  font-weight: 800;
  /* vertical-align: middle; */
  position: relative;
}

.heard .imgs1 {
  height: 2px;
  width: 45%;
  /* float: left; */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.heard .imgs2 {
  height: 2px;
  width: 45%;
  /* float: left; */
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.heard div {
  height: 100%;
  width: 10%;
  min-width: 80px;
  /* float: left; */
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.list {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: relative;
  font-size: 14px;
}

.left {
  width: 48%;
  float: left;
  text-align: left;
}

.left p {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.right p {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.left p:nth-child(2) {
  text-align: left;
}

.right p:nth-child(2) {
  text-align: left;
}

.right {
  width: 48%;
  float: right;
  text-align: right;
}

.tishi {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  float: left;
}

.aa {
  color: #565351;
  /* display: inline-block; */
  /* float: left; */
}

.bb {
  color: #81ad99;
  /* display: inline-block; */
  /* float: left; */
}

.list1 {
  width: 100%;
  height: 75px;
  margin-top: 30px;
  background-color: #e0e9f7;
}
.bottsssom {
  width: 100%;
  line-height: 68px;
  text-align: center;
}
.bottom {
  width: 33%;
  text-align: center;
  float: left;
}

.bottom p {
  height: 35px;
  line-height: 35px;
}

.anniu {
  width: 100%;
  margin-top: 50px;
}

.btn {
  width: 80px;
  height: 20px;
  padding: 10px;
  border: 1px solid #4d8efc;
  border-radius: 40px;
  margin: 30px auto;
  text-align: center;
  line-height: 20px;
}
.qingchu {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
